<template>
  <table class="table table-bordered">
    <thead class="thead-dark">
      <tr>
        <th class="text-center">#</th>
        <th>Text</th>
        <th class="text-center">Aktive Studios</th>
        <th class="text-center">Bearbeiten</th>
        <th class="text-center">Löschen</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="5">
          <router-link :to="{name: 'broadcast-create'}">+ Broadcast erstellen</router-link>
        </td>
      </tr>
      <tr v-for="(broadcast, index) in broadcasts" :key="broadcast.id">
        <th class="text-center">{{ index + 1 }}</th>
        <td>{{ broadcast.text }}</td>
        <td class="text-center">{{ broadcast.studios.length}}</td>
        <td class="text-center">
          <router-link :to="{name: 'broadcast-details', params: {id: broadcast.id}}">
            <i class="fa fa-edit text-dark"></i>
          </router-link>
        </td>
        <td class="text-center" @click="remove(broadcast.id)">
          <i class="fa fa-trash cursor-pointer"></i>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { firestore } from '../firebase';

export default {
  data() {
    return {
      broadcasts: [],
      loading: false
    };
  },
  async created() {
    this.loading = true;

    const broadcastsSnapshot = await firestore
      .collection('broadcasts')
      .orderBy('text')
      .get();

    this.broadcasts = broadcastsSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    this.loading = false;
  },
  methods: {
    async remove(id) {
      if (
        confirm('Bist du sicher, dass du diesen Broadcast löschen möchtest?')
      ) {
        await firestore
          .collection('broadcasts')
          .doc(id)
          .delete();

        this.broadcasts = this.broadcasts.filter(
          broadcast => broadcast.id !== id
        );
      }
    }
  }
};
</script>
